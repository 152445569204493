import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
//last
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  safeBox: {
    paddingLeft: "env(safe-area-inset-left)",
    paddingRight: "env(safe-area-inset-right)"
  }
}));

function GContainer(props) {
  const classes = useStyles();
  const { maxW, ...rest } = props;

  return (
    <Box className={classes.safeBox} width={1}>
      <Container maxWidth={maxW ? maxW : "lg"} {...rest} />
    </Box>
  );
}

export default GContainer;
