import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2d2d2d"
    },
    secondary: {
      main: "#0070c9"
    },
    error: {
      main: "#f44336"
    },
    background: {
      default: "#fff"
    }
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(",")
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1024,
      xl: 1440
    }
  }
});
theme = responsiveFontSizes(theme);

export default theme;
