import { AppBar, SvgIcon, Link, Toolbar, Typography, Drawer } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Link as GastsbyLink } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import theme from "../theme";
import GContainer from "./GContainer";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//The CSS specificity relies on the import order. If you want the guarantee that the wrapped component's style will be overridden, you need to import the Box last.
import Box from "@material-ui/core/Box";

function isInternal(url) {
  return /^\/(?!\/)/.test(url);
}

function getRouter(url) {
  let router;
  if (isInternal(url)) {
    router = { component: GastsbyLink, to: url };
  } else {
    router = { href: url, target: "_blank", rel: "noopener" };
  }
  return router;
}

const useLinkStyles = makeStyles({
  appLink: {
    WebkitTapHighlightColor: "transparent",
    "&:hover": {
      opacity: "0.65"
    }
  }
});

function AppBarLink(props) {
  const classes = useLinkStyles();

  const { url, title, ...rest } = props;
  const router = getRouter(url);

  return (
    <Link
      {...router}
      className={classes.appLink}
      variant="inherit"
      color="inherit"
      underline="none"
      noWrap
      {...rest}
    >
      {title}
    </Link>
  );
}

function MenuItem(props) {
  const { url, title, ...rest } = props;
  const boxedTitle = (
    <Box py={2} px={3}>
      {title}
    </Box>
  );
  return <AppBarLink url={url} title={boxedTitle} {...rest} />;
}

function MenuDrawer(props) {
  return (
    <Box style={{ paddingRight: "env(safe-area-inset-right)" }}>
      <Box
        pt={4}
        width={240}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        fontWeight="fontWeightMedium"
        fontSize="1.125rem"
      >
        <MenuItem title="Apps" url="/" {...props} />
        <MenuItem title="DataMan" url="/dataman" {...props} />
        <MenuItem title="Support" url="/support" {...props} />
        <MenuItem title="Twitter" url="https://twitter.com/xvisionnow" {...props} />
        <MenuItem title="Facebook" url="https://www.facebook.com/xvisionapps" {...props} />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  "@global": {
    html: {
      textSizeAdjust: "none"
    }
  },

  menuIcon: {
    cursor: "pointer",
    WebkitTapHighlightColor: "transparent",
    "&:hover": {
      opacity: "0.65"
    }
  },

  drawerPaper: {
    backgroundColor: "#222",
    color: "#fff"
  },

  footerBox: {
    paddingBottom: 28,
    "@supports (padding: max(0px))": {
      paddingBottom: "max(28px, env(safe-area-inset-bottom))"
    }
  }
}));

export default function TopLayout(props) {
  const [open, setOpen] = React.useState(false);

  //show full menu toolbar when in iPad portrait
  const matchedW = useMediaQuery("(min-width:768px)");

  const classes = useStyles();

  const openDrawer = (flag, now = false) => () => {
    //open immediately
    if (flag == true) {
      setOpen(true);
      return;
    }

    //close immediately
    if (now) {
      setOpen(false);
      return;
    }

    //delay close to let page load
    setTimeout(() => {
      setOpen(false);
    }, 150);
  };

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta
          name="description"
          content="Discover DataMan, the simplest and clearest app to track data usage on iPhone and Apple Watch. P.S. we invent many other awesome apps too :)"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap"
          rel="stylesheet"
        />
        <meta
          name="apple-itunes-app"
          content="app-id=592962356, affiliate-data=at=10l9Ek&ct=smartbanner"
        />
        <html lang="en" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppBar position="static" elevation={0}>
          <Toolbar disableGutters>
            <GContainer>
              <Box
                justifyContent="space-between"
                flexWrap="nowrap"
                display="flex"
                alignItems="center"
                fontWeight="fontWeightMedium"
                fontSize="1.125rem"
              >
                <AppBarLink title="XVision" url="/" />
                {matchedW ? (
                  <>
                    <AppBarLink title="Apps" url="/" />
                    <AppBarLink title="DataMan" url="/dataman" />
                    <AppBarLink title="Support" url="/support" />
                    <AppBarLink title="Twitter" url="https://twitter.com/xvisionnow" />
                    <AppBarLink title="Facebook" url="https://www.facebook.com/xvisionapps" />
                  </>
                ) : (
                  <Box
                    width={44}
                    height={44}
                    pl="20px"
                    pt="10px"
                    color="inherit"
                    className={classes.menuIcon}
                    onClick={openDrawer(true)}
                  >
                    <SvgIcon>
                      <path d="M3.111 17H20.89C21.5 17 22 16.55 22 16s-.5-1-1.111-1H3.11C2.5 15 2 15.45 2 16s.5 1 1.111 1zM2 8c0 .55.5 1 1.111 1H20.89C21.5 9 22 8.55 22 8s-.5-1-1.111-1H3.11C2.5 7 2 7.45 2 8z" />
                    </SvgIcon>
                  </Box>
                )}
              </Box>
            </GContainer>
          </Toolbar>
          <Drawer
            anchor="right"
            open={open}
            onClose={openDrawer(false, true)}
            classes={{ paper: classes.drawerPaper }}
          >
            <MenuDrawer onClick={openDrawer(false)} />
          </Drawer>
        </AppBar>

        {props.children}

        <Box bgcolor="#f2f2f2" color="#888" mt={4} pt={9} className={classes.footerBox}>
          <GContainer>
            <Typography variant="body2" align="right" color="inherit">
              Copyright © 2022 XVision. All rights reserved.
            </Typography>
          </GContainer>
        </Box>
      </ThemeProvider>
    </>
  );
}
